import { method } from "lodash";
import { FETCH_SYS_GUARD_DATA_SOURCE, getDatasourceWithType, SET_SYS_GUARD_DATASOURCE, SET_SYS_GUARD_PAGE_LOADER } from "./sysGuardConstants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";

const { takeEvery, put } = require("redux-saga/effects");

function* fetchSysGuardDatasource(action) {
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true});
  const type = action.payload;
  let options = {
    method: "GET",
    url: getDatasourceWithType(type),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res?.data;
      yield put ({ type: SET_SYS_GUARD_DATASOURCE, payload: data});
      yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false});
    }
  } catch (err) {
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false});
    console.log(err);
  }
}
function* SysGuardSaga() {
  yield takeEvery(FETCH_SYS_GUARD_DATA_SOURCE, fetchSysGuardDatasource);
}

export default SysGuardSaga;
