import { SET_SYS_GUARD_DATASOURCE, SET_SYS_GUARD_PAGE_LOADER } from "../sysGuardConstants";

const initialState = {
  sysGuardDataSource: null,
  loader: false
};

export const SysGuardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SYS_GUARD_DATASOURCE: {
      return {
        ...state,
        sysGuardDataSource: action.payload,
      };
    }
    case SET_SYS_GUARD_PAGE_LOADER: {
      return {
        ...state,
        loader: action.payload
      }
    }
    default:
      return state;
  }
};
