import ErrorIcon from "../../assets/icons/ErrorIcon.svg";
import ConnectedStatusOD from "../../assets/icons/ConnedtedStatusOD.svg";
import NotConnected from "../../assets/icons/NotConnected.svg";
import { domain_vizr } from "../../redux/constants/constants";
export const SYS_GUARD = "mdr-sysGuard";
export const SET_ALIAS_NAME = "SET_ALIAS_NAME";
export const SET_APPLICATION_NAME = "SET_APPLICATION_NAME";
export const SET_DOMAIN_NAME = "SET_DOMAIN_NAME";
export const SET_HOST_TYPE = "SET_HOST_TYPE";
export const SET_ENGINE = "SET_ENGINE";
export const SET_APPLICATION_NAME_LIST = "SET_APPLICATION_NAME_LIST";
export const RESET_SYS_GUARD = "RESET_SYS_GUARD";
export const FETCH_SYS_GUARD_DATA_SOURCE = "FETCH_SYS_GUARD_DATA_SOURCE";
export const SET_SYS_GUARD_DATASOURCE = "SET_SYS_GUARD_DATASOURCE";
export const GraphToolTip =
  "Access to users, roles, sites, files data through the Microsoft Graph API";
export const statusMapping = {
  Connected: ConnectedStatusOD,
  "Not Connected": NotConnected,
  Error: ErrorIcon,
};

export const isSysGuard = () => {
  const location = window.location.pathname;
  return location === "/mdr/sysguard";
};

export const getDatasourceWithType = (type) => {
  return `${domain_vizr}/tenant/dataSourceType/${type}`;
};

export const SET_SYS_GUARD_PAGE_LOADER = "SET_SYS_GUARD_PAGE_LOADER";
